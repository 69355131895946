import AvatarImage from "@assets/images/avatar.png";
import AvatarFemaleImage from "@assets/images/avatar-female.png";
import { Spiner, SpinerPage } from "@components/loaders/spiner";
import {
  FormBiography,
  formBiographySchema,
} from "@components/resolvers/school.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchoolService } from "@services/school.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const StudentBioPage = () => {
  const location = useLocation();
  const { pushNotification } = useNotificationStore((state) => state);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const { data: dataNis } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    queryFn: SchoolService.studentBio,
  });
  const nis = dataNis?.data?.nis ?? "";

  const { data, isPending, refetch } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIOGRAPHY, nis],
    queryFn: () => SchoolService.studentBiography(nis),
  });

  const { register, handleSubmit, setValue } = useForm<FormBiography>({
    resolver: yupResolver<FormBiography>(formBiographySchema),
  });

  const { mutate: onUpdateBio, isPending: isPendingUpdateBio } = useMutation({
    mutationFn: SchoolService.updateStudentBiography,
    onSuccess: (response) => {
      pushNotification({
        type: response.success ? "success" : "error",
        message: response.message,
      });
      refetch();
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFooter(false);
    setHeader({
      type: "small",
      title: "Biodata Santri",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Biodata Santri" });
  }, [setFooter, setHeader, setAppBar, location.pathname]);

  useEffect(() => {
    setValue("nis", data?.data?.nis ?? "");
    setValue("namasiswa", data?.data?.namasiswa ?? "");
    setValue("kotaLahir", data?.data?.kotalahir ?? "");
    setValue("tanggalLahir", data?.data?.tanggallahir ?? "");
    setValue("namaAyah", data?.data?.namaayah ?? "");
    setValue("namaIbu", data?.data?.namaibu ?? "");
    setValue("alamat", data?.data?.alamat ?? "");
    setValue("telephon", data?.data?.telephon ?? "");
    setValue("pendidikanAyah", data?.data?.pendidikanayah ?? "");
    setValue("pendidikanIbu", data?.data?.pendidikanibu ?? "");
    setValue("pekerjaanAyah", data?.data?.pekerjaanayah ?? "");
    setValue("pekerjaanIbu", data?.data?.pekerjaanibu ?? "");
  }, [data, setValue]);

  const handleInvalid = (event: any) => {
    event.target.setCustomValidity("kolom ini harus diisi");
  };
  const handleInput = (event: any) => {
    event.target.setCustomValidity("");
  };

  return (
    <>
      <SpinerPage show={isPending} />
      <div className="card card-style">
        <div className="content mb-0">
          <div className="d-flex">
            <div>
              <img
                src={
                  dataNis?.data?.gender === "Pria" ||
                  dataNis?.data?.gender === "PRIA" ||
                  dataNis?.data?.gender === ""
                    ? AvatarImage
                    : AvatarFemaleImage
                }
                width="50"
                alt="avatar"
                className="me-3 bg-highlight rounded-xl"
              />
            </div>
            <div>
              <h1 className="mb-0 pt-1">{dataNis?.data?.nama ?? "-"}</h1>
              <p className="color-highlight font-11 mt-n2 mb-1">
                Kelas {data?.data?.kelas ?? "-"}
              </p>
              <p className="color-highlight font-11 mt-n2">
                Markaz {data?.data?.markaz ?? "-"}
              </p>
            </div>
          </div>
          <hr className="mb-3" />
          <form
            autoComplete="off"
            onSubmit={handleSubmit((data) => onUpdateBio(data))}
          >
            <span className="tw-font-medium tw-text-base">Biodata Lengkap</span>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4 mt-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("namasiswa", { value: data?.data?.namasiswa })}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Nama Lengkap
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                placeholder={data?.data?.nis ?? "-"}
                disabled={true}
                {...register("nis", { value: data?.data?.nis })}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                NIS
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("kotaLahir", { value: data?.data?.kotalahir })}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Tempat Lahir
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="date"
                className="form-control bg-theme"
                {...register("tanggalLahir", {
                  value: moment().format("YYYY-MM-DD"),
                })}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Tanggal Lahir
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("namaAyah", { value: data?.data?.namaayah })}
                required
                onInvalid={(event) => handleInvalid(event)}
                onInput={(event) => handleInput(event)}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Nama Ayah
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("pendidikanAyah", {
                  value: data?.data?.pendidikanayah,
                })}
                required
                onInvalid={(event) => handleInvalid(event)}
                onInput={(event) => handleInput(event)}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Pendidikan Ayah
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("pekerjaanAyah", {
                  value: data?.data?.pekerjaanayah,
                })}
                required
                onInvalid={(event) => handleInvalid(event)}
                onInput={(event) => handleInput(event)}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Pekerjaan Ayah
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("namaIbu", { value: data?.data?.namaibu })}
                required
                onInvalid={(event) => handleInvalid(event)}
                onInput={(event) => handleInput(event)}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Nama Ibu
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("pendidikanIbu", {
                  value: data?.data?.pendidikanibu,
                })}
                required
                onInvalid={(event) => handleInvalid(event)}
                onInput={(event) => handleInput(event)}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Pendidikan Ibu
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("pekerjaanIbu", {
                  value: data?.data?.pekerjaanibu,
                })}
                required
                onInvalid={(event) => handleInvalid(event)}
                onInput={(event) => handleInput(event)}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Pekerjaan Ibu
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                {...register("alamat", { value: data?.data?.alamat })}
                required
                onInvalid={(event) => handleInvalid(event)}
                onInput={(event) => handleInput(event)}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Alamat
              </label>
            </div>

            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                placeholder="081234567890"
                inputMode="numeric"
                minLength={10}
                maxLength={16}
                onKeyUp={(event) => {
                  setTimeout(() => {
                    (event.target as HTMLInputElement).value = (
                      event.target as HTMLInputElement
                    ).value.replace(/-/g, "");
                  }, 0);
                }}
                {...register("telephon", { value: data?.data?.telephon })}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Telepon
              </label>
            </div>

            <div className="divider my-3"></div>

            <button className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-3 tw-w-full tw-h-11 mb-4">
              {isPendingUpdateBio ? <Spiner /> : "Simpan Perubahan"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
