import { PopupConfirm, PopupCustom } from "@components/generals/popup";
import { Spiner, SpinerPage } from "@components/loaders/spiner";
import {
  CreatePin,
  createPinSchema,
} from "@components/resolvers/marketplace.resolvers";
import {
  PocketTransfer,
  pocketTransferSchema,
  TransferBetweenAcc,
  transferBetweenAccSchema,
} from "@components/resolvers/pocket.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountService } from "@services/account.service";
import { PocketHistory } from "@services/dtos/finance.dto";
import { FinanceService } from "@services/finance.service";
import { MarketplaceService } from "@services/marketplace.service";
import { SchoolService } from "@services/school.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { FcFinePrint, FcPackage } from "react-icons/fc";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

export const PocketHistoryPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const { pushNotification } = useNotificationStore((state) => state);

  const [range, setRange] = useState<"WEEK" | "MONTH">("WEEK");
  const [type, setType] = useState<"ALL" | "IN" | "OUT">("ALL");
  const [popupTransfer, setPopupTransfer] = useState<boolean>(false);
  const [popupSetPin, setPopupSetPin] = useState<boolean>(false);
  const [popupMode, setPopupMode] = useState<boolean>(false);
  const [popupConfirm, setPopupConfirm] = useState<boolean>(false);
  const [popupConfirmSetPin, setPopupConfirmSetPin] = useState<boolean>(false);
  const [transferBetweenAcc, setTransferBetweenAcc] = useState<boolean>(false);
  const [nominal, setNominal] = useState<number>(0);
  const [formData, setFormData] = useState({});

  const { register, handleSubmit, reset } = useForm<PocketTransfer>({
    resolver: yupResolver<PocketTransfer>(pocketTransferSchema),
  });

  const {
    register: registerTransferBetweenAcc,
    handleSubmit: handleSubmitTransferBetweenAcc,
    reset: resetTransfer,
    getValues: getValuesTransferBetweenAcc,
  } = useForm<TransferBetweenAcc>({
    resolver: yupResolver<TransferBetweenAcc>(transferBetweenAccSchema),
  });

  const {
    register: registerPIN,
    handleSubmit: handleSubmitPIN,
    reset: resetPIN,
  } = useForm<CreatePin>({
    resolver: yupResolver<CreatePin>(createPinSchema),
  });

  const { data: accountProfile } = useQuery({
    queryKey: [AccountService.queries.GET_ACCOUNT_PROFILE],
    queryFn: AccountService.profile,
  });

  const { data: students } = useQuery({
    queryKey: [AccountService.queries.GET_ACCOUNT_STUDENTS],
    queryFn: AccountService.students,
  });

  const { data: bio } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    queryFn: SchoolService.studentBio,
  });

  const { data: pockets, refetch: refetchPockets } = useQuery({
    queryKey: [FinanceService.queries.GET_FINANCE_POCKET],
    queryFn: () => FinanceService.pocket(),
  });

  const { data, isPending, refetch } = useQuery({
    queryKey: [FinanceService.queries.GET_FINANCE_POCKET_HISTORY, range, type],
    queryFn: () =>
      FinanceService.pocketHistory(state.typePocket, { range, type }),
  });

  const { mutate: checkPin, isPending: isPendingCheckPin } = useMutation({
    mutationFn: MarketplaceService.checkPin,
    onSuccess: (response) => {
      if (response.success) {
        setPopupTransfer(true);
      } else if (response.errorCode === "E_UNPROCESSABLE_ENTITY") {
        setPopupConfirmSetPin(true);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const { mutate: createPin, isPending: isPendingCreatePin } = useMutation({
    mutationFn: MarketplaceService.createPin,
    onSuccess: (response) => {
      pushNotification({
        type: response.success ? "success" : "error",
        message: response.message,
      });
      resetPIN();
      setPopupSetPin(false);
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const { mutate, isPending: isPendingTransfer } = useMutation({
    mutationFn: FinanceService.pocketTransfer,
    onSuccess: (response) => {
      if (response.success) {
        setPopupTransfer(false);
        setNominal(response.data);
        refetch();
        reset();
        pushNotification({
          type: "success",
          message: "Berhasil transfer, silahkan check saldo.",
        });
      } else {
        resetPIN();
        setPopupSetPin(false);
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const {
    mutate: mutateTransferBetweenAcc,
    isPending: isPendingTransferBetweenAcc,
  } = useMutation({
    mutationFn: FinanceService.transferBetweenAcc,
    onSuccess: (response) => {
      if (response.status) {
        setPopupTransfer(false);
        setPopupConfirm(false);
        setNominal(response.data);
        refetch();
        refetchPockets();
        resetTransfer();
        pushNotification({
          type: "success",
          message: "Berhasil transfer, silahkan check saldo.",
        });
      } else {
        resetPIN();
        setPopupSetPin(false);
        setPopupConfirm(false);
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: `Detail ${state.namePocket}`,
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ leading: "back", title: `Detail ${state.namePocket}` });
    const foundPocket = pockets?.data?.find(
      (item) => item.namePocket === state.namePocket
    );
    setNominal(foundPocket?.balance ?? 0);
  }, [setFooter, setHeader, setAppBar, setNominal, state]);

  const onOpenDetail = (pocketHistory: PocketHistory) => {
    navigate(linkPage.FINANCE_POCKET_HISTORY_DETAIL, { state: pocketHistory });
  };

  const onCreatePin = async (payload: CreatePin) => {
    if (
      payload.newPin !== payload.confirmPin ||
      `${payload.newPin}`.length > 6 ||
      `${payload.confirmPin}`.length > 6
    ) {
      pushNotification({
        type: "error",
        message: "PIN yang kamu masukan tidak sama",
      });
      return;
    }
    createPin(`${payload.confirmPin}`);
  };

  const onConfirmTransferBetweenAcc = (data: TransferBetweenAcc) => {
    mutateTransferBetweenAcc(data);
  };

  return (
    <>
      <SpinerPage show={isPendingTransfer || isPendingTransferBetweenAcc} />
      <div className="card card-style mb-3">
        <div className="content mb-3">
          <div className="d-flex">
            <div className="my-auto tw-grow">
              <button
                // onClick={() => checkPin()}
                onClick={() => {
                  state.namePocket === "Tabungan"
                    ? setPopupMode(true)
                    : checkPin();
                }}
                className="btn btn-xs rounded-xs text-uppercase font-900 shadow-s bg-blue-dark"
                disabled={state.balance <= 0 || state.balance === undefined}
              >
                {isPendingCheckPin ? <Spiner /> : "Transfer"}
              </button>
            </div>
            <div className="tw-mr-1">
              <FcPackage className="mx-auto tw-mb-2" size={50} />
            </div>
            <div className="color-theme">
              <div>Total Saldo</div>
              <h5 className="m0">
                {
                  nominal > 0
                    ? toRp(nominal)
                    : // : state.balance === undefined
                      toRp(0)
                  // : toRp(state.balance)
                }
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-style">
        <div className="content mb-0">
          <p className="mb-3">
            Data difilter dari{" "}
            {moment()
              .subtract(
                1,
                (range.toLowerCase() +
                  "s") as moment.unitOfTime.DurationConstructor
              )
              .format("DD MMMM YYYY")}{" "}
            - {moment().format("DD MMMM YYYY")}
          </p>

          <div className="input-style has-borders input-style-always-active no-icon mb-4">
            <label htmlFor="form5" className="color-highlight font-500">
              Filter Range
            </label>
            <select
              onChange={(e) => setRange(e.target.value as "WEEK" | "MONTH")}
              defaultValue="WEEK"
              className="bg-theme"
            >
              <option value="default" disabled>
                Filter Range
              </option>
              <option value="WEEK">7 Hari Terakhir</option>
              <option value="MONTH">30 Hari Terakhir</option>
            </select>
            <span>
              <i>
                <FaChevronDown />
              </i>
            </span>
          </div>
          <div className="input-style has-borders input-style-always-active no-icon mb-4">
            <label htmlFor="form5" className="color-highlight font-500">
              Tipe Transaksi
            </label>
            <select
              onChange={(e) => setType(e.target.value as "ALL" | "IN" | "OUT")}
              defaultValue="ALL"
              className="bg-theme"
            >
              <option value="default" disabled>
                Tipe Transaksi
              </option>
              <option value="ALL">Semua Transaksi</option>
              <option value="IN">Uang Masuk</option>
              <option value="OUT">Uang Keluar</option>
            </select>
            <span>
              <i>
                <FaChevronDown />
              </i>
            </span>
          </div>

          {isPending ? (
            <div className="d-flex p-3">
              <Spiner className="!tw-text-gray-200 tw-fill-gray-500 dark:tw-fill-white dark:!tw-text-gray-600 tw-w-8 tw-h-8 m-auto " />
            </div>
          ) : data?.data != null && data.data.length > 0 ? (
            <div className="list-group list-group-flush">
              {data.data.map((item, idx) => (
                <div
                  key={idx}
                  className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                  onClick={() => onOpenDetail(item)}
                >
                  <div
                    className={`rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto ${
                      item.filter === "IN" ? "bg-green-dark" : "bg-red-dark"
                    }`}
                  >
                    <RiMoneyDollarCircleFill className="m-auto" size={18} />
                  </div>
                  <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                    <strong className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                      {item.filter === "IN" ? "Uang Masuk" : "Uang Keluar"}
                    </strong>
                    <strong className="tw-font-normal tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                      {item.trxId ?? "-"}
                    </strong>
                  </div>
                  <div className="my-auto tw-flex tw-flex-col">
                    <strong className="tw-font-normal tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                      {moment(item.updateAt).format("DD MMM YYYY")}
                    </strong>
                    <span className="tw-font-bold tw-text-sm tw-truncate">
                      {toRp(item.nominal)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="tw-p-8 text-center ">
              <FcFinePrint size={50} />
              <div>Data tidak ditemukan</div>
            </div>
          )}
        </div>
      </div>

      <PopupCustom
        show={popupTransfer}
        position="bottom"
        height={290}
        onCancel={() => setPopupTransfer(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 mt-3">
            Transfer Antar {!transferBetweenAcc ? "Kantong" : "Akun"}
          </h4>
          <div className="divider mt-3 mb-4"></div>
          {!transferBetweenAcc ? (
            <form
              autoComplete="off"
              onSubmit={handleSubmit((data) => mutate(data))}
            >
              <input
                type="hidden"
                value={state.id}
                {...register("from", {
                  value: state.id,
                })}
              />
              <div className="input-style has-borders input-style-always-active no-icon mb-4">
                <label htmlFor="form5" className="color-highlight font-500">
                  Pilih Kantong Tujuan
                </label>
                <select
                  defaultValue=""
                  className="bg-theme"
                  {...register("to")}
                >
                  <option value="default" disabled>
                    Pilih Kantong Bayar
                  </option>
                  {pockets?.data
                    ?.filter((item) => item.id !== state.id)
                    .map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.namePocket} (
                        {item.balance ? toRp(item.balance) : toRp(0)})
                      </option>
                    ))}
                </select>
                <span>
                  <i>
                    <FaChevronDown />
                  </i>
                </span>
              </div>

              <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                <input
                  type="number"
                  className="form-control bg-theme"
                  placeholder="Nominal"
                  inputMode="numeric"
                  min={0}
                  max={parseInt(state.balance)}
                  {...register("amount")}
                  // onChange={(e) => {
                  //   const value = e.target.value.replace(/\D/g, "");
                  //   e.target.value = formatValue(value);
                  // }}
                />
                <label
                  htmlFor="form1"
                  className="color-highlight font-400 font-13"
                >
                  Nominal
                </label>
              </div>

              <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                <input
                  type="password"
                  className="form-control bg-theme"
                  placeholder=""
                  {...register("pin")}
                  minLength={6}
                  maxLength={6}
                  inputMode="numeric"
                />
                <label
                  htmlFor="form1"
                  className="color-highlight font-400 font-13"
                >
                  PIN Transaksi
                </label>
              </div>

              <button
                type="submit"
                className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
              >
                Transfer
              </button>
            </form>
          ) : (
            <form
              autoComplete="off"
              // onSubmit={handleSubmitTransferBetweenAcc((data) =>
              //   mutateTransferBetweenAcc(data)
              // )}
              onSubmit={(e) => {
                e.preventDefault();
                setFormData(getValuesTransferBetweenAcc());
                setPopupConfirm(true);
                setPopupTransfer(false);
              }}
            >
              <div className="input-style has-borders input-style-always-active no-icon mb-4">
                <input
                  type="hidden"
                  value={bio?.data?.nis}
                  {...registerTransferBetweenAcc("from", {
                    value: bio?.data?.nis,
                  })}
                />
                <input
                  type="hidden"
                  value={bio?.data?.nis}
                  {...registerTransferBetweenAcc("id_pengirim", {
                    value: accountProfile?.data?.account?.username,
                  })}
                />
                <input
                  type="hidden"
                  value={bio?.data?.nis}
                  {...registerTransferBetweenAcc("sumber_tabungan", {
                    value: state.namePocket.toUpperCase(),
                  })}
                />
                <input
                  type="hidden"
                  value={bio?.data?.nis}
                  {...registerTransferBetweenAcc("tujuan_tabungan", {
                    value: state.namePocket.toUpperCase(),
                  })}
                />
                <div className="input-style has-borders input-style-always-active no-icon mb-4">
                  <label htmlFor="form5" className="color-highlight font-500">
                    Pilih Santri
                  </label>
                  <select
                    className="bg-theme"
                    {...registerTransferBetweenAcc("to")}
                  >
                    <option value="default" disabled>
                      Pilih Santri
                    </option>

                    {students?.data
                      ?.filter((item) => item.nis !== bio?.data?.nis)
                      .map((item, idx) => (
                        <option key={idx} value={item.nis}>
                          {item.nama} - {item.nis}
                        </option>
                      ))}
                  </select>
                  <span>
                    <i>
                      <FaChevronDown />
                    </i>
                  </span>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <input
                    type="number"
                    className="form-control bg-theme"
                    placeholder="Nominal"
                    inputMode="numeric"
                    min={0}
                    max={parseInt(state.balance)}
                    {...registerTransferBetweenAcc("nominal")}
                  />
                  <label
                    htmlFor="form1"
                    className="color-highlight font-400 font-13"
                  >
                    Nominal
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <input
                    type="password"
                    className="form-control bg-theme"
                    placeholder=""
                    {...registerTransferBetweenAcc("pin")}
                    minLength={6}
                    maxLength={6}
                    inputMode="numeric"
                  />
                  <label
                    htmlFor="form1"
                    className="color-highlight font-400 font-13"
                  >
                    PIN Transaksi
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
              >
                Transfer
              </button>
            </form>
          )}
        </div>
      </PopupCustom>

      <PopupConfirm
        height={200}
        show={popupConfirmSetPin}
        title="Belum ada PIN"
        message="PIN transaksi anda saat ini belum dibuat, buat PIN transaksi sekarang? "
        textConfirm="Ya, Buat"
        textCancel="Kembali"
        onConfirm={() => {
          setPopupSetPin(true);
          setPopupConfirmSetPin(false);
        }}
        onCancel={() => setPopupConfirmSetPin(false)}
      />

      <PopupCustom
        show={popupSetPin}
        position="bottom"
        height={290}
        onCancel={() => setPopupSetPin(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 mt-3">Buat PIN Transaksi</h4>
          <div className="divider mt-3 mb-4"></div>
          <form autoComplete="off" onSubmit={handleSubmitPIN(onCreatePin)}>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="password"
                className="form-control bg-theme"
                placeholder=""
                {...registerPIN("newPin")}
                minLength={6}
                maxLength={6}
                inputMode="numeric"
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Masukan PIN
              </label>
            </div>

            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="password"
                className="form-control bg-theme"
                placeholder=""
                {...registerPIN("confirmPin")}
                minLength={6}
                maxLength={6}
                inputMode="numeric"
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Konfirmasi PIN
              </label>
            </div>

            <button
              type="submit"
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
            >
              {isPendingCreatePin ? <Spiner /> : "Buat PIN Transaksi"}
            </button>
          </form>
        </div>
      </PopupCustom>

      <PopupCustom
        show={popupMode}
        position="bottom"
        height={290}
        onCancel={() => setPopupMode(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 mt-3">Pilih Transfer</h4>
          <div className="divider mt-3 mb-4"></div>
          <button
            onClick={() => {
              setPopupMode(false);
              setTransferBetweenAcc(true);
              checkPin();
            }}
            className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
          >
            Transfer Antar Akun
          </button>
          <button
            onClick={() => {
              setPopupMode(false);
              setTransferBetweenAcc(false);
              checkPin();
            }}
            className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
          >
            Transfer Antar Kantong
          </button>
        </div>
      </PopupCustom>

      <PopupConfirm
        show={popupConfirm}
        title="Kirim Uang"
        message={`Apakah anda yakin akan mengirim uang ${getValuesTransferBetweenAcc(
          "sumber_tabungan"
        )} dari NIS ${getValuesTransferBetweenAcc(
          "from"
        )} ke NIS ${getValuesTransferBetweenAcc("to")}?`}
        textConfirm="Ya, Kirim"
        textCancel="Batalkan"
        onConfirm={() => {
          // console.log(formData);
          onConfirmTransferBetweenAcc(formData as TransferBetweenAcc);
        }}
        onCancel={() => setPopupConfirm(false)}
        isProfilePage={false}
      />
    </>
  );
};
