import { ComponentProps } from "@declarations/props";
import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";

export const PopupConfirm: ComponentProps<{
  show: boolean;
  height?: number;
  width?: number;
  title: string;
  message: string;
  textConfirm?: string;
  textCancel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  isProfilePage?: boolean;
}> = ({
  show,
  height = 224,
  width = 324,
  title,
  message,
  textConfirm = "Ya",
  textCancel = "Tidak",
  onConfirm = () => {},
  onCancel = () => {},
  isProfilePage = false,
}) => {
  return (
    <>
      <div
        className={`menu-hider ${show && "menu-active"}`}
        onClick={onCancel}
      />
      <div
        className={`menu menu-box-modal rounded-m !tw-block ${
          show && "menu-active"
        }`}
        style={{
          height: `${height}px`,
          width: `${width}px`,
        }}
      >
        <h1 className="text-center font-700 mt-3 pb-1">{title}</h1>
        <p className="boxed-text-l">{message}</p>
        <div className="row me-3 ms-3 mb-0">
          <div className="col-6">
            <div
              onClick={onConfirm}
              className={`close-menu btn btn-sm btn-full button-s shadow-l rounded-s font-900 bg-green-dark ${
                isProfilePage ? "text-confirm" : ""
              }`}
            >
              {textConfirm}
            </div>
          </div>
          <div className="col-6">
            <div
              onClick={onCancel}
              className={`close-menu btn btn-sm btn-full button-s shadow-l rounded-s font-900 bg-red-dark ${
                isProfilePage ? "text-cancel" : ""
              }`}
            >
              {textCancel}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PopupSuccess: ComponentProps<{
  show: boolean;
  height?: number;
  width?: number;
  title: string;
  message: string;
  textButton?: string;
  onConfirm?: () => void;
}> = ({
  show,
  height = 300,
  width = 324,
  title,
  message,
  textButton = "Ok",
  onConfirm = () => {},
}) => {
  return (
    <>
      <div className={`menu-hider ${show && "menu-active"}`} />
      <div
        className={`menu menu-box-modal rounded-m !tw-block ${
          show && "menu-active"
        }`}
        style={{
          height: `${height}px`,
          width: `${width}px`,
        }}
      >
        <h1 className="text-center mt-3 pt-1">
          <FaCheckCircle
            className="color-green-dark shadow-xl rounded-circle"
            size={70}
          />
        </h1>
        <h1 className="text-center mt-3 font-700">{title}</h1>
        <p className="boxed-text-l">{message}</p>
        <div
          onClick={onConfirm}
          className="close-menu btn btn-m btn-center-m button-s shadow-l rounded-s text-uppercase font-900 bg-green-light"
        >
          {textButton}
        </div>
      </div>
    </>
  );
};

export const PopupCustom: ComponentProps<{
  show: boolean;
  position?: "center" | "bottom";
  height?: number;
  onCancel?: () => void;
}> = ({
  show,
  position = "center",
  height = 224,
  onCancel = () => {},
  children,
}) => {
  return (
    <>
      <div
        className={`menu-hider ${show && "menu-active"}`}
        onClick={onCancel}
      />
      <div
        className={`menu ${
          position === "center"
            ? "menu-box-modal "
            : "menu-box-bottom menu-box-detached"
        } rounded-m !tw-block !tw-min-h-0 ${show && "menu-active"}`}
        style={{
          minHeight: `${height}px`,
          maxHeight: "calc(100vh - 35px)",
        }}
      >
        {children}
      </div>
    </>
  );
};

export const PopupError: ComponentProps<{
  show: boolean;
  height?: number;
  width?: number;
  title: string;
  message: string;
  textButton?: string;
  onConfirm?: () => void;
}> = ({
  show,
  height = 300,
  width = 324,
  title,
  message,
  textButton = "Ok",
  onConfirm = () => {},
}) => {
  return (
    <>
      <div className={`menu-hider ${show && "menu-active"}`} />
      <div
        className={`menu menu-box-modal rounded-m !tw-block ${
          show && "menu-active"
        }`}
        style={{
          height: `${height}px`,
          width: `${width}px`,
        }}
      >
        <h1 className="text-center mt-3 pt-1">
          <FaRegTimesCircle
            className="color-red-dark shadow-xl rounded-circle"
            size={70}
          />
        </h1>
        <h1 className="text-center mt-3 font-700">{title}</h1>
        <p className="boxed-text-l">{message}</p>
        <div
          onClick={onConfirm}
          className="close-menu btn btn-m btn-center-m button-s shadow-l rounded-s text-uppercase font-900 bg-green-light"
        >
          {textButton}
        </div>
      </div>
    </>
  );
};
