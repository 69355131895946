import { ObjectSchema, number, object, string } from "yup";

// ------------- POCKET TRANSFER -------------
export type PocketTransfer = {
  from: number;
  to: number;
  amount: number;
  pin: string;
};

export type TransferBetweenAcc = {
  from: string;
  to: string;
  nominal: number;
  pin: string;
  sumber_tabungan: string;
  tujuan_tabungan: string;
  id_pengirim: string;
};

export const pocketTransferSchema: ObjectSchema<PocketTransfer> = object({
  from: number().required(),
  to: number().required(),
  amount: number().required(),
  pin: string().max(6).min(6).required(),
});

export const transferBetweenAccSchema: ObjectSchema<TransferBetweenAcc> =
  object({
    from: string().required(),
    to: string().required(),
    nominal: number().required(),
    pin: string().max(6).min(6).required(),
    sumber_tabungan: string().required(),
    tujuan_tabungan: string().required(),
    id_pengirim: string().required(),
  });
// ------------- POCKET TRANSFER -------------
